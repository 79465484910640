import { AccountCreate } from './AccountCreate';
import { AccountEdit } from './AccountEdit';
import { AccountList, AccountIcon } from './AccountList';

export default {
    icon: AccountIcon,
    list: AccountList,
    create: AccountCreate,
    edit: AccountEdit,
};
