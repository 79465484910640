import React from 'react';
import { required, SimpleForm, TextInput, Edit } from 'react-admin';

export const AccountEdit = () => {
    return (
        <Edit title="Edit a Account">
            <SimpleForm>
                <TextInput source="code" validate={[required()]} />
                <TextInput source="oms_url" validate={required()} />
            </SimpleForm>
        </Edit>
    );
};
