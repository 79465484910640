import React, { useEffect, useState } from 'react';
import {
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    useDataProvider,
    Edit,
    BooleanInput,
} from 'react-admin';

export const SubaccountEdit = () => {
    const dataProvider = useDataProvider();
    const [accounts, setAccounts] = useState<{ id: any; name: any }[]>([]);

    useEffect(() => {
        const fetchAccounts = async () => {
            let page = 1;
            const perPage = 500;
            const sort = { field: 'id', order: 'ASC' };
            const filter = {};
            let done = false;
            let data: { id: any; code: any }[] = [];
            while (!done) {
                const res = await dataProvider.getList('accounts', {
                    pagination: { page, perPage },
                    sort,
                    filter,
                });
                data = data.concat(res.data);
                if (res === undefined || res.data.length < perPage) {
                    done = true;
                }
                page++;
            }
            setAccounts(
                data.map(account => ({
                    id: account.id,
                    name: account.code,
                }))
            );
        };
        fetchAccounts();
    }, [dataProvider]);

    return (
        <Edit title="Edit a Subaccount">
            <SimpleForm>
                <TextInput source="code" validate={[required()]} />
                <SelectInput
                    source="account"
                    choices={accounts}
                    validate={required()}
                />
                <TextInput source="alias" validate={required()} />
                <SelectInput
                    source="environment"
                    choices={[
                        { id: 'live', name: 'Live' },
                        { id: 'demo', name: 'Demo' },
                    ]}
                    validate={required()}
                    defaultValue="live"
                />
                <SelectInput
                    source="currency"
                    choices={[
                        { id: 'USD', name: 'USD' },
                        { id: 'USDT', name: 'USDT' },
                        { id: 'EUR', name: 'EUR' },
                        { id: 'RUB', name: 'RUB' },
                    ]}
                    validate={required()}
                    defaultValue="USD"
                />
                <BooleanInput source="trading_allowed" validate={required()} />
                <BooleanInput
                    source="include_in_reports"
                    validate={required()}
                />
            </SimpleForm>
        </Edit>
    );
};
