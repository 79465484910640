import React from 'react';
import { required, SimpleForm, TextInput, Create } from 'react-admin';

export const AccountCreate = () => {
    return (
        <Create title="Create a Account" redirect="list">
            <SimpleForm>
                <TextInput source="code" validate={[required()]} />
                <TextInput source="oms_url" validate={required()} />
            </SimpleForm>
        </Create>
    );
};
