import { SubaccountCreate } from './SubaccountCreate';
import { SubaccountEdit } from './SubaccountEdit';
import { SubaccountList, SubaccountIcon } from './SubaccountList';

export default {
    icon: SubaccountIcon,
    list: SubaccountList,
    create: SubaccountCreate,
    edit: SubaccountEdit,
};
