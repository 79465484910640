import React, { ReactElement } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import {
    httpClientWithAuth,
    initOptions,
    keycloak,
    onKeycloakEvent,
} from './auth_provider/authHandlers';
import { GlobalStyles, createTheme } from '@mui/material';
import { defaultTheme, mergeTranslations, Resource } from 'react-admin';
import { Admin, buildI18nProvider } from '@react-admin/ra-enterprise';
import CssBaseline from '@mui/material/CssBaseline';
import { raTreeLanguageEnglish } from '@react-admin/ra-tree';
import { Login, Layout } from './layout';
import englishMessages from './i18n/en';
import { getThemes } from './layout/themes';

import dataProvider from './data_provider';
import { useAuthProvider } from './auth_provider/authProvider';

import instrumentMapping from './instrument_mapping';
import { dagsterQuotes } from './dagster_quotes';
import manifests from './manifests';
import dockerTags from './docker_tags';
import {
    historyFetcherInstruments,
    historyFetcherTasks,
} from './history_fetcher';
import {
    exanteInstrumentInfo,
    exanteInstruments,
    exanteDonorMonitor,
} from './instruments/exante';
import {
    finamInstrumentInfo,
    finamInstruments,
    finamDonorMonitor,
} from './instruments/finam';
import {
    binanceInstrumentInfo,
    binanceInstruments,
} from './instruments/binance';
import {
    refinitivInstruments,
    refinitivDonorMonitor,
} from './instruments/refinitiv';
import { ibInstrumentInfo, ibInstruments } from './instruments/ib';
import {
    saxoInstrumentInfo,
    saxoInstruments,
    saxoDonorMonitor,
    saxoTradingTimes,
} from './instruments/saxo';
import { cqgInstrumentInfo } from './instruments/cqg';
import {
    rapid_portfolio,
    rapid_portfolio_launch,
    rapid_trader,
    rapid_portfolio_launch_schedule,
    rapid_portfolio_launch_config,
    active_rapid_trader_monitor,
} from './rapid_portfolio';
import summary_report from './summary_report';
import instrument_mapping_errors from './instrument_mapping_errors';
import actual_contracts from './actual_contracts';
import all_contracts from './all_contracts';
import fund_movements from './fund_movements';
import subaccounts from './subaccounts';
import portfolio_pl from './portfolio_pl';
import subaccount_pl from './subaccount_pl';
import { pl_emergency_brake } from './pl_emergency_brake';
import instrument_group from './catalogs/instrument_group';
import calendar_events from './catalogs/calendar_events';
import market_data_schedule from './catalogs/market_data_schedule';
import { oms_trade } from './oms_trade';
import { cmeInstruments } from './instruments/cme';
import subaccount_snapshot_report from './subaccount_snapshot_report';
import accounts from './accounts';

const messages = {
    en: mergeTranslations(englishMessages, raTreeLanguageEnglish),
};

const i18nProvider = buildI18nProvider(messages, 'en');

const App = (): ReactElement => {
    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            LoadingComponent={<div></div>}
            initOptions={initOptions}
            onEvent={onKeycloakEvent}
        >
            <React.Fragment>
                <AdminWithKeyCloak />
            </React.Fragment>
        </ReactKeycloakProvider>
    );
};

const AdminWithKeyCloak = () => {
    const theme = createTheme(defaultTheme);
    const customAuthProvider = useAuthProvider();
    const { darkTheme, lightTheme } = getThemes(theme);

    return (
        <Admin
            title=""
            dataProvider={dataProvider('api', httpClientWithAuth)}
            authProvider={customAuthProvider}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            lightTheme={lightTheme}
            darkTheme={darkTheme}
        >
            <GlobalStyles styles={{ body: { margin: 100 } }} />
            <CssBaseline />
            <Resource name="portfolio_pl" {...portfolio_pl} />
            <Resource name="subaccount_pl" {...subaccount_pl} />
            <Resource name="instrument_mapping" {...instrumentMapping} />
            <Resource name="dagster_quotes" {...dagsterQuotes} />
            <Resource name="docker_tags" {...dockerTags} />
            <Resource name="trader_events" />
            <Resource name="pods" />
            <Resource name="manifests" {...manifests} />
            <Resource name="exante_instruments" {...exanteInstruments} />
            <Resource name="finam_instruments" {...finamInstruments} />
            <Resource name="binance_instruments" {...binanceInstruments} />
            <Resource name="saxo_instruments" {...saxoInstruments} />
            <Resource name="ib_instruments" {...ibInstruments} />
            <Resource name="refinitiv_instruments" {...refinitivInstruments} />
            <Resource name="cme_instruments" {...cmeInstruments} />
            <Resource
                name="exante_instrument_details"
                {...exanteInstrumentInfo}
            />
            <Resource
                name="finam_instrument_details"
                {...finamInstrumentInfo}
            />
            <Resource
                name="binance_instrument_details"
                {...binanceInstrumentInfo}
            />
            <Resource name="saxo_instrument_info" {...saxoInstrumentInfo} />
            <Resource name="ib_instrument_info" {...ibInstrumentInfo} />
            <Resource name="cqg_instrument_info" {...cqgInstrumentInfo} />
            <Resource name="exante_donor_monitor" {...exanteDonorMonitor} />
            <Resource name="finam_donor_monitor" {...finamDonorMonitor} />
            <Resource name="saxo_donor_monitor" {...saxoDonorMonitor} />
            <Resource
                name="refinitiv_donor_monitor"
                {...refinitivDonorMonitor}
            />
            <Resource name="saxo_trading_times" {...saxoTradingTimes} />
            <Resource
                name="history_fetcher_instruments"
                {...historyFetcherInstruments}
            />
            <Resource name="history_fetcher_tasks" {...historyFetcherTasks} />
            <Resource name="history_fetcher_tasks_metrics" />
            <Resource name="history_fetcher_task_reset" />
            <Resource name="history_fetcher_task_exclude" />

            <Resource name="rapid_portfolio" {...rapid_portfolio} />
            <Resource
                name="rapid_portfolio_launch"
                {...rapid_portfolio_launch}
            />
            <Resource name="rapid_trader" {...rapid_trader} />
            <Resource
                name="active_rapid_trader_monitor"
                {...active_rapid_trader_monitor}
            />
            <Resource name="rapid_portfolio_config" />
            <Resource name="rapid_trader_config" />
            <Resource
                name="rapid_portfolio_launch_schedule"
                {...rapid_portfolio_launch_schedule}
            />
            <Resource
                name="rapid_portfolio_launch_config"
                {...rapid_portfolio_launch_config}
            />

            <Resource name="signal_executions/expanded" />

            <Resource name="summary_report" {...summary_report} />
            <Resource
                name="instrument_mapping_errors"
                {...instrument_mapping_errors}
            />
            <Resource name="actual_contracts" {...actual_contracts} />
            <Resource name="all_contracts" {...all_contracts} />
            <Resource name="fund_movements" {...fund_movements} />
            <Resource name="subaccounts" {...subaccounts} />
            <Resource name="accounts" {...accounts} />
            <Resource name="pl_emergency_brake" {...pl_emergency_brake} />
            <Resource
                name="instrument_group"
                {...instrument_group}
                recordRepresentation="code"
            />
            <Resource name="calendar_events" {...calendar_events} />
            <Resource name="market_data_schedule" {...market_data_schedule} />
            <Resource name="oms_trade" {...oms_trade} />
            <Resource
                name="subaccount_snapshot"
                {...subaccount_snapshot_report}
            />
        </Admin>
    );
};

export default App;
