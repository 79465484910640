import React, { FC } from 'react';
import {
    TextField,
    List,
    TextInput,
    TopToolbar,
    FilterButton,
    Datagrid,
    CreateButton,
    EditButton,
    usePermissions,
} from 'react-admin';

import AddCardIcon from '@mui/icons-material/AddCard';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

const requiredPermissions = ['head', 'developer', 'senior operator'];

const checkPermissions = (permissions: string[] | undefined): boolean => {
    return requiredPermissions.some(permission =>
        permissions?.includes(permission)
    );
};

const listFilters = [
    <TextInput key="code" label="Account" source="code__icontains" alwaysOn />,
];

export const AccountIcon = AddCardIcon;

const AccountListActions: FC = () => {
    const { permissions } = usePermissions();
    return (
        <>
            <TopToolbar>
                <FilterButton />
                {checkPermissions(permissions) && <CreateButton />}
            </TopToolbar>
        </>
    );
};

export const AccountList = () => {
    return (
        <List
            title="Accounts"
            filters={listFilters}
            sort={{ field: 'code', order: 'DESC' }}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            actions={<AccountListActions />}
        >
            <AccountDataGrid />
        </List>
    );
};

export const AccountDataGrid = () => {
    const { permissions } = usePermissions();
    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="code" label="Account" />
            <TextField source="oms_url" label="Oms Url" />
            {checkPermissions(permissions) && <EditButton />}
        </Datagrid>
    );
};
