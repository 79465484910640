import AddCardIcon from '@mui/icons-material/AddCard';
import React, { FC, useEffect, useState } from 'react';
import {
    BooleanField,
    CreateButton,
    Datagrid,
    EditButton,
    FilterButton,
    FunctionField,
    List,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    useDataProvider,
    usePermissions,
} from 'react-admin';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';
import { StartTradingButton, StopTradingButton } from './Buttons';

const requiredPermissions = ['head', 'developer', 'senior operator'];

const checkPermissions = (permissions: string[] | undefined): boolean => {
    return requiredPermissions.some(permission =>
        permissions?.includes(permission)
    );
};

const listFilters = [
    <TextInput
        key="code"
        label="Subaccount"
        source="code__icontains"
        alwaysOn
    />,
    <TextInput key="alias" label="Alias" source="alias__icontains" alwaysOn />,
    <SelectInput
        key="trading_allowed"
        label="Trading allowed"
        source="trading_allowed"
        choices={[
            { id: true, name: 'Allowed' },
            { id: false, name: 'Forbidden' },
        ]}
        alwaysOn
    />,
];

export const SubaccountIcon = AddCardIcon;

const SubaccountListActions: FC = () => {
    const { permissions } = usePermissions();
    return (
        <>
            <TopToolbar>
                <FilterButton />
                {checkPermissions(permissions) && <CreateButton />}
            </TopToolbar>
        </>
    );
};

export const SubaccountList = () => {
    return (
        <List
            title="Trader Launches"
            filters={listFilters}
            sort={{ field: 'trading_allowed', order: 'DESC' }}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            actions={<SubaccountListActions />}
        >
            <SubaccountDataGrid />
        </List>
    );
};

export const SubaccountDataGrid = () => {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const [accounts, setAccounts] = useState<{ [id: number]: string }>({});

    useEffect(() => {
        const fetchAccounts = async () => {
            let page = 1;
            const perPage = 500;
            const sort = { field: 'id', order: 'ASC' };
            const filter = {};
            let done = false;
            let data: { id: any; code: any }[] = [];
            while (!done) {
                const res = await dataProvider.getList('accounts', {
                    pagination: { page, perPage },
                    sort,
                    filter,
                });
                data = data.concat(res.data);
                if (res === undefined || res.data.length < perPage) {
                    done = true;
                }
                page++;
            }
            const accountsHashTable = data.reduce((acc, account) => {
                acc[account.id] = account.code;
                return acc;
            }, {} as { [id: number]: string });
            setAccounts(accountsHashTable);
        };
        fetchAccounts();
    }, [dataProvider]);

    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="code" label="Subaccount" />
            <TextField source="alias" label="Alias" />
            <TextField source="currency" label="Currency" />
            <TextField source="environment" label="Environment" />
            <FunctionField
                label="Account"
                render={record => accounts[record.account] || record.account}
            />
            <BooleanField source="trading_allowed" label="Trading allowed" />
            <BooleanField
                source="include_in_reports"
                label="Include in reports"
            />
            <StopTradingButton />
            <StartTradingButton />
            {checkPermissions(permissions) && <EditButton />}
        </Datagrid>
    );
};
